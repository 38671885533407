import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { PrivacyPolicy } from "./components/privacy-policy";
//import JsonData from "./data/data.json";
import grandalat_data from "./data/data.js";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(grandalat_data);
  }, []);

  return (
    <Router>

      <div>
        <Navigation />
        <Routes>

          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route exact path="/" element={
            <>
            <Header data={landingPageData.Header} />
              <Features data={landingPageData.Features} />
              <About data={landingPageData.About} />
              <Services data={landingPageData.Services} />
              <Gallery data={landingPageData.Gallery} />
              <Testimonials data={landingPageData.Testimonials} />
              {/* {<Team data={landingPageData.Team} />} */}
              </>
          } />
        </Routes>

          <Contact data={landingPageData.Contact} />
      </div>
    </Router>

  );
};

export default App;
